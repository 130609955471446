@import '../../assets/sass/variables';

.info-layout {
  height: 100%;
  &__container {
    position: relative;
    // height: 100%;
    &-header-background {
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
      &-image {
        height: 100px;
        background: url(../../assets/images/panda-texture.png) no-repeat,
          $hovered-red;
        filter: blur(2px);
        box-shadow: 0 0 100px rgba($color: #000000, $alpha: 1) inset;
        background-size: cover;
        position: absolute;
        top: 0;
        width: 100%;
      }
      &-overlay {
        height: 100px;
        position: relative;
        z-index: 1;
        background-color: rgba($color: $main, $alpha: 0.3);
      }
    }
    &-body {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      padding: 0 20px;
      max-width: 1200px;
      height: 100%;
      box-sizing: border-box;
      @media screen and (max-width: 1168px) {
        margin-top: 60px;
      }
      &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        &-outlet {
          min-height: 500px;
          height: 100%;
          width: 100%;
          padding: 0 15px;
          box-sizing: border-box;
          margin-top: 75px;
        }
      }
    }
  }
}
