@import '../../assets/sass/variables';

.info-section {
  padding: 30px;
  width: 100%;
  color: $white;
  box-sizing: border-box;
  min-height: 445px;
  @media screen and (max-width: 768px) {
    min-height: auto;
    padding: 20px 0;
  }
  &__container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
    box-sizing: border-box;
    &-menu {
      display: flex;
      gap: 20px;
      align-items: center;
      &-logo {
        img {
          max-width: 70px;
          max-height: 70px;
        }
      }
      &-links {
        display: flex;
        gap: 30px;
        &-link {
          cursor: pointer;
          font-weight: 900;
          font-size: 16px;
          transition: all 0.1s ease-in-out;
          color: $white;
          &:hover {
            color: $red;
          }
        }
      }
    }
    &-products {
      display: flex;
      width: 100%;
      justify-content: space-between;
      height: 325px;
      @media screen and (max-width: 768px) {
        height: 250px;
      }
      @media screen and (max-width: 600px) {
        height: 200px;
      }
      @media screen and (max-width: 468px) {
        height: 150px;
      }
      @media screen and (max-width: 300px) {
        height: 100px;
      }
    }
  }
  &__swiper {
    &-container {
      box-shadow: 1px 0px 20px -5px $main;
      width: 100%;
      width: 820px;
      margin: 0;
      border-radius: 10px;
    }
  }
  .slider-img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }
}
