@import '../../../../assets/sass/variables';

.cart-page-item-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  background-color: $white;
  box-shadow: 1px 0px 10px -5px gray;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
  &__info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    &-content {
      display: flex;
      flex-direction: column;
      gap: 5px;
      &-image {
        display: flex;
        align-items: center;
        position: relative;
        & img {
          border-radius: 5px;
          width: 100%;
          object-fit: cover;
        }
        &-props {
          display: flex;
          gap: 5px;
          position: absolute;
          top: 5px;
          right: 5px;
          z-index: 1;
          &-prop {
            background-color: $white;
            border-radius: 5px;
            padding: 3px;
            box-sizing: border-box;
            color: rgb(34, 200, 34);
            font-weight: bold;
          }
        }
      }
      &-text {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        &-content {
          height: 100%;
          &-title {
            color: $red;
            font-weight: bold;
            white-space: nowrap;
          }
          &-description {
            font-size: 14px;
            max-width: 400px;
          }
        }
      }
    }
    &-actions {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 10px;
      justify-content: space-between;
      &-default {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 10px;
        &-price {
          &-discount {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 2px;
            &-old {
              font-size: 12px;
              text-decoration: line-through;
            }
            &-new {
              font-size: 16px;
              font-weight: bold;
            }
          }
        }
      }
      &-total_price {
        font-weight: bold;
      }
    }
  }
}
