@import '../../../../assets/sass/variables';

.locations-page {
  padding: 20px;
  box-sizing: border-box;
  &__add_product {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    &-btn {
      min-width: 150px;
    }
  }

  &__locations {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    width: 100%;
    @media screen and (max-width: 1168px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      color: $red;
      box-sizing: border-box;
      box-shadow: 1px 0px 12px -5px gray;
      &-actions {
        display: flex;
        gap: 5px;
      }
    }
  }
}
