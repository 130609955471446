@import '../../assets/sass/variables';

.cart-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  color: $main;
  border-radius: 10px;
  bottom: auto;
  position: sticky;
  top: 10px;
  margin-top: 103px;
  width: 255px;
  height: 325px;
  box-shadow: 1px 0px 20px -5px gray;
  padding: 10px;
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
  &__data {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-size: 12px;
    overflow-y: scroll;
    max-height: 210px;
  }
  &-no_data {
    display: flex;
    text-align: center;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
  &-delivery {
    font-size: 16px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  &-divider {
    border-bottom: 1px solid $secondary;
  }
  &-total {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  &-add_to_cart {
    margin-top: 10px;
  }
  &-note {
    color: gray;
    font-size: 10px;
    text-align: center;
    &-time_limit {
      color: $red;
      font-size: 10px;
      text-align: center;
    }
  }
}
