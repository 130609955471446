@import '../../assets/sass/_variables.scss';

.sidebar-menu {
  position: fixed;
  left: -100%;
  top: 0;
  background-color: $black;
  z-index: 3;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  padding: 20px;
  box-sizing: border-box;
  &.open {
    left: 0;
  }
  &__close {
    width: 100%;
    text-align: end;
    font-size: 24px;
    color: $white;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 20px 0;
    box-sizing: border-box;
    &-logo {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100px;
        max-height: 100px;
        border-radius: 10px;
      }
    }
    &-links {
      display: flex;
      flex-direction: column;
      gap: 10px;
      &-link {
        text-align: center;
        background-color: $red;
        color: $white;
        border-radius: 5px;
        padding: 5px 0;
        box-sizing: border-box;
      }
    }
    &-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      &-contact {
        display: flex;
        align-items: center;
        gap: 20px;
        &-icon {
          color: $red;
          & svg {
            width: 20px;
            height: 20px;
          }
        }
        &-item {
          display: flex;
          flex-direction: column;
          gap: 5px;
          color: $white;
          &-label {
            font-weight: bold;
          }
          &-value {
            &:hover {
              color: $red;
            }
            & svg {
              width: 20px;
              height: 20px;
              cursor: pointer;
              path {
                fill: $white;
              }
              &:hover {
                path {
                  fill: $red;
                }
              }
            }
          }
        }
      }

      &-socials {
        display: flex;
        align-items: center;
        gap: 20px;
        &-icon {
          color: $red;
          & svg {
            width: 20px;
            height: 20px;
          }
        }
        &-item {
          display: flex;
          flex-direction: column;
          gap: 5px;
          color: $white;
          &-label {
            font-weight: bold;
          }
          &-value {
            &:hover {
              color: $red;
            }
            & svg {
              width: 20px;
              height: 20px;
              cursor: pointer;
              path {
                fill: $white;
              }
              &:hover {
                path {
                  fill: $red;
                }
              }
            }
          }
        }
      }
    }
  }
}
