@import '../../assets/sass/_variables.scss';

.admin-sidebar {
  background-color: $main;
  color: $white;
  position: relative;
  box-shadow: 1px 0px 20px 0px $main;
  width: 100%;
  max-width: 400px;
  min-width: 300px;
  height: 100%;
  min-height: 100vh;

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-menu {
      width: 100%;

      &-item {
        padding: 15px 20px;
        cursor: pointer;
        text-decoration: none;

        &.active {
          background-color: $red;
        }

        &:hover {
          background-color: $red;
        }
      }
    }
  }
}