@import '../../assets/sass/variables';

.home-page {
  &__menu-items {
    & .spinner {
      background: url(../../assets/icons/ajax-loader.gif) 50% 50% no-repeat #fff;
      background-size: 210px;
      height: 200px;
      width: 100%;
    }
    &.no-data {
      display: flex;
      align-items: center;
      grid-template-columns: auto;
      height: 300px;
    }
    &-no_data {
      color: $black;
      font-weight: bold;
      font-size: 24px;
      text-align: center;
      width: 100%;
    }
    &-container {
      &-title {
        text-align: center;
        color: $hovered-red;
        font-size: 20px;
        font-weight: bold;
        padding: 10px 0;
        margin-bottom: 20px;
        border-bottom: 3px solid $hovered-red;
      }
      &-products {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        width: 100%;
        @media screen and (max-width: 1168px) {
          grid-template-columns: 1fr 1fr;
        }
        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}
