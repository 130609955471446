@import '../../assets/sass/variables';

.products-cost-layout {
  display: flex;
  gap: 20px;

  &__container {
    padding: 20px;
    max-width: 600px;
    width: 100%;
    border-right: 3px solid $hovered-red;
    max-height: 100vh;
    overflow-y: auto;
    box-sizing: border-box;

    &__header {
      margin-bottom: 50px;
      position: sticky;
      top: 20px;
      width: 100%;
      z-index: 1;

      &-search {
        width: 100%;
      }
    }

    &__add_product {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;

      &-btn {
        min-width: 150px;
      }
    }

    &__products-cost {
      display: flex;
      flex-direction: column;
      gap: 50px;

      &-container {
        display: flex;
        flex-direction: column;

        &-category {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          color: $red;
          box-sizing: border-box;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          border-bottom: 3px solid $hovered-red;
        }

        &-items {
          margin-top: 20px;
          display: grid;
          gap: 20px;
          width: 100%;

          &-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            color: $red;
            box-sizing: border-box;
            box-shadow: 1px 0px 12px -5px gray;
            gap: 5px;

            &-actions {
              display: flex;
              align-items: center;
              gap: 5px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}