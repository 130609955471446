@import '../../../../../assets/sass/variables';

.products-stats-modal {
  width: 100%;
  max-height: 50rem;
  padding: 20px;
  box-sizing: border-box;

  &-close {
    text-align: end;

    & svg {
      cursor: pointer;
    }
  }

  &-title {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    color: $main;
  }

  &__content {
    padding: 10px;
    box-sizing: border-box;

    &-picker {
      display: flex;
      flex-direction: column;
      gap: 20px;

      &-clock {
        box-shadow: 1px 0px 8px -5px gray;
      }
    }

    &-submit {
      margin-top: 20px;
    }
  }
}

.products-stats-modal-container {
  position: absolute;
  inset: 40px;
  box-shadow: 1px 0px 10px 0px $main;
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  height: fit-content;
  max-width: 80rem;
  margin: auto;
}