@import '../../../../assets/sass/variables';

.general-page {
  padding: 20px;
  box-sizing: border-box;
  &__title {
    font-weight: bold;
    text-align: center;
    font-size: 24px;
    padding: 20px 0;
    box-sizing: border-box;
    color: $red;
  }
  &__banner {
    margin-top: 20px;
  }
  &__submit {
    margin-top: 20px;
  }
}
