@import '../../assets/sass/variables';

.main-layout {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .body-container {
    position: relative;
    .header-background {
      position: absolute;
      top: 60px;
      width: 100%;
      z-index: -1;
      &-image {
        height: 500px;
        background: url(../../assets/images/panda-texture.png) no-repeat,
          $hovered-red;
        filter: blur(2px);
        box-shadow: 0 0 100px rgba($color: #000000, $alpha: 1) inset;
        background-size: cover;
        position: absolute;
        top: 0;
        width: 100%;
        @media screen and (max-width: 768px) {
          height: 320px;
        }
        @media screen and (max-width: 600px) {
          height: 260px;
        }
        @media screen and (max-width: 468px) {
          height: 200px;
        }
        @media screen and (max-width: 300px) {
          height: 140px;
        }
      }
      & .header-overlay {
        height: 500px;
        position: relative;
        z-index: 1;
        background-color: rgba($color: $main, $alpha: 0.3);
        @media screen and (max-width: 768px) {
          height: 320px;
        }
        @media screen and (max-width: 600px) {
          height: 260px;
        }
        @media screen and (max-width: 468px) {
          height: 200px;
        }
        @media screen and (max-width: 300px) {
          height: 140px;
        }
      }
    }
    .body-content {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      padding: 0 20px;
      max-width: 1200px;
      height: 100%;
      box-sizing: border-box;
      @media screen and (max-width: 1168px) {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 70px;
        margin-bottom: 20px;
      }
      & .info-section-container {
        display: flex;
        flex-direction: column;
        gap: 150px;
        max-width: 850px;
        @media screen and (max-width: 1168px) {
          width: 100%;
        }
        @media screen and (max-width: 768px) {
          gap: 120px;
        }
        @media screen and (max-width: 468px) {
          gap: 50px;
        }
        & .menu-content {
          padding: 0 15px;
          box-sizing: border-box;
        }
      }
    }
  }
}
