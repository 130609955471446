@import '../../assets/sass/variables';

.header-menu {
  padding: 15px;
  width: 100%;
  color: $white;
  box-sizing: border-box;
  &__container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
    box-sizing: border-box;
    &-menu {
      display: flex;
      gap: 20px;
      align-items: center;
      @media screen and (max-width: 768px) {
        gap: 5px;
      }
      &-logo {
        img {
          cursor: pointer;
          max-width: 65px;
          max-height: 65px;
        }
      }
      &-links {
        display: flex;
        gap: 30px;
        &-link {
          @media screen and (max-width: 768px) {
            font-size: 12px;
            white-space: nowrap;
          }
          cursor: pointer;
          font-weight: 900;
          font-size: 16px;
          transition: all 0.1s ease-in-out;
          color: $white;
          &:hover {
            color: $red;
          }
        }
      }
    }
  }
}
