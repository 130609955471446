@import '../../assets/sass/variables';

.admin-layout {
  &__info {
    display: flex;
    width: 100%;
    &-content {
      width: 100%;
    }
  }
}
