@import '../../../../assets/sass/variables';

.products-cost-page {
    width: 100%;
    padding: 50px;
    max-height: 100vh;
    overflow-y: auto;
    box-sizing: border-box;

    &__container {
        display: flex;
        flex-direction: column;
        gap: 50px;
        width: 100%;

        &-up_button {
            position: fixed;
            bottom: 20px;
            right: 20px;
            z-index: 1;
            color: $white;
        }

        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &-title {
                font-size: 24px;
                font-weight: bold;
                color: $red;
            }

            &-cost {
                font-size: 24px;
                font-weight: bold;
                color: $red;
            }
        }

        &-content {
            &-items {
                display: flex;
                flex-direction: column;
                gap: 20px;
                margin-top: 20px;
                width: 100%;

                &-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 10px;
                    padding: 10px;
                    color: $red;
                    box-sizing: border-box;
                    box-shadow: 1px 0px 12px -5px gray;

                    &-radios {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                    }
                }
            }
        }

        &-submit {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            width: 100%;

            & button {
                max-width: 150px;
            }
        }
    }
}