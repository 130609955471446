@import '../../assets/sass/variables';

.cart-page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  &__container {
    width: 800px;
    &-title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      padding: 10px 0;
      margin-bottom: 20px;
      box-sizing: border-box;
      border-bottom: 3px solid $red;
    }
    &-products {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-no_data {
        color: $red;
        font-weight: bold;
        font-size: 24px;
        text-align: center;
        height: 100%;
        width: 100%;
      }
    }
    &-additions {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin: 10px 0;
      padding: 10px 0;
      border-top: 3px solid $red;
      box-sizing: border-box;
      &-title {
        color: $red;
        text-align: center;
        font-weight: bold;
      }
      &-content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 5px;
        column-gap: 5px;
        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr 1fr 1fr;
        }
        @media screen and (max-width: 468px) {
          grid-template-columns: 1fr 1fr;
          column-gap: 2px;
          margin-bottom: 40px;
        }
        @media screen and (max-width: 300px) {
          grid-template-columns: 1fr;
        }
        &-item {
          height: 100%;
        }
      }
    }
    &-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px 0;
      box-sizing: border-box;
      margin-top: 20px;
      border-top: 3px solid $red;
      width: 100%;
      @media screen and (max-width: 768px) {
        justify-content: center;
      }
      &-total {
        display: flex;
        flex-direction: column;
        min-width: 280px;
        gap: 5px;
        @media screen and (max-width: 768px) {
          width: 100%;
          min-width: auto;
        }
        &-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &-value {
            font-weight: bold;
          }
          &.total {
            font-weight: bold;
            color: $red;
          }
        }
      }
    }
  }
  &-note {
    color: gray;
    font-size: 10px;
    text-align: center;
    &-time_limit {
      color: $red;
      font-size: 10px;
      text-align: center;
    }
  }
}
