@import '../../assets/sass/variables';

.contacts-button {
  position: fixed;
  bottom: 20px;
  left: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 1;
  &__items {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    display: none;
    &.is-open {
      opacity: 1;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    &-item {
      width: 50px;
      height: 50px;
      color: $white;
      background-color: $red;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      & a:visited {
        text-decoration: none;
        color: $white;
      }
      & a:any-link {
        text-decoration: none;
        color: $white;
      }
      & a:hover {
        text-decoration: none;
        color: $white;
      }
      & a:focus {
        text-decoration: none;
        color: $white;
      }
      & a:active {
        text-decoration: none;
        color: $white;
      }
      & svg {
        fill: $white;
      }
    }
  }
  &__action {
    width: 50px;
    height: 50px;
    color: $white;
    background-color: $red;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 0px 12px -5px $main;

    & svg {
      fill: $white;
    }
  }
}
