@import '../../assets/sass/variables';

.categories-slider {
  padding: 20px 0;
  box-sizing: border-box;
  background-color: $white;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  z-index: 1;
  @media screen and (max-width: 768px) {
    padding-bottom: 0;
    position: relative;
  }
  &__categories {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    &-item {
      justify-content: center;
      display: flex;
      align-items: center;
      text-align: center;
      width: 100%;
      color: $red;
      cursor: pointer;
      font-weight: bold;
      padding: 5px 0;
      box-shadow: 1px 0px 10px -5px gray;
      border-radius: 5px;
      &.active {
        color: $white;
        background-color: $red;
        border-radius: 5px;
        &:hover {
          color: $white;
          background-color: $hovered-red;
        }
      }
      &:hover {
        color: $hovered-red;
      }
    }
  }
  .slider-item {
    text-align: center;
    width: fit-content;
    color: $red;
    cursor: pointer;
    font-weight: bold;
    padding: 5px 0;
    min-height: 35px;
    display: flex !important;
    align-items: center;
    justify-content: center;

    &.active {
      color: $white;
      background-color: $red;
      border-radius: 5px;
      &:hover {
        color: $white;
        background-color: $hovered-red;
      }
    }
    &:hover {
      color: $hovered-red;
    }
  }
  .slick-prev,
  .slick-next {
    color: $red;
    z-index: 1;
    &:hover {
      color: $hovered-red;
    }
  }
  & .slick-next {
    right: -25px;
  }
  & .slick-prev {
    left: -25px;
  }
}
