@import '../../assets/sass/_variables.scss';

.main-navbar {
  background-color: $main;
  color: $white;
  padding: 15px 20px;
  position: relative;
  box-shadow: 1px 0px 20px 0px $main;
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1200px;
    box-sizing: border-box;
    &-nav-left {
      display: flex;
      gap: 20px;
      font-size: 14px;
      &-container {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        gap: 10px;
        &-phone {
          text-align: center;
          &-number:last-of-type {
            font-size: 13px;
          }
        }
      }
      &-schedule {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
    &-nav-right {
      display: flex;
      gap: 20px;
      font-size: 14px;
      & div {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}
