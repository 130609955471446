@import '../../assets/sass/variables';

.quantity-input {
  display: flex;
  align-items: center;
  gap: 10px;
  &__action {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $red;
    color: $white;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    cursor: pointer;
    user-select: none;
    &:hover {
      background-color: $hovered-red;
    }
  }
  &__value {
    text-align: center;
    width: 20px;
  }
}
