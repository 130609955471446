@import '../../../assets/sass/_variables.scss';

.main-navbar-mobile {
  background-color: $main;
  color: $white;
  padding: 15px 0;
  position: relative;
  box-shadow: 1px 0px 20px 0px $main;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1200px;
    box-sizing: border-box;
    &-nav-left {
      display: flex;
      gap: 20px;
      font-size: 14px;
      &-container {
        display: flex;
        align-items: center;
        font-size: 25px;
        font-weight: 700;
        gap: 10px;
      }
    }
    &-nav-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 8px;
      gap: 5px;
      margin-left: -12px;
      img {
        cursor: pointer;
        max-width: 40px;
        max-height: 40px;
      }
    }
    &-nav-right {
      display: flex;
      font-size: 20px;
      align-items: center;
      &-cart {
        cursor: pointer;
        position: relative;
        &-quantity {
          position: absolute;
          background-color: $red;
          border-radius: 50%;
          font-size: 10px;
          padding: 3px;
          box-sizing: border-box;
          top: -10px;
          left: 15px;
          min-width: 18px;
          height: 18px;
          text-align: center;
        }
      }
    }
  }
}
