@import '../../../assets/sass/variables';

.product-view-mobile {
  margin-top: 50px;
  &__card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: $white;
    box-shadow: 1px 0px 10px -5px gray;
    transition: transform 0.3s ease-in-out;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    min-height: 250px;
    min-width: 300px;
    &:hover {
      transform: scale(1.02);
    }
    &-image {
      height: 100%;
      position: relative;
      & img {
        border-radius: 5px;
        object-fit: cover;
        width: 100%;
        max-height: 260px;
      }
      &-props {
        display: flex;
        gap: 5px;
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 1;
        &-prop {
          background-color: $white;
          border-radius: 5px;
          padding: 3px;
          box-sizing: border-box;
          color: rgb(34, 200, 34);
          font-weight: bold;
        }
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      &-info {
        &-title {
          font-size: 20px;
          font-weight: bold;
          color: $red;
        }
      }
      &-cart {
        display: flex;
        flex-direction: column;
        gap: 5px;
        &-weight {
          text-align: end;
          font-size: 18px;
          font-weight: bold;
        }
        &-price {
          text-align: end;
          font-size: 24px;
          font-weight: bold;
          color: $red;
          &-discount {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: flex-end;
            gap: 2px;
            width: 100%;
            &-old {
              font-size: 16px;
              text-decoration: line-through;
            }
            &-new {
              font-size: 16px;
            }
          }
        }
        &-add {
          padding: 10px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }
}
