@import '../../../../../assets/sass/variables';

.categories-modal {
  width: 100%;
  &-close {
    text-align: end;
    & svg {
      cursor: pointer;
    }
  }
  &-title {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    color: $main;
  }
  &__content {
    padding: 10px;
    box-sizing: border-box;
    &-submit {
      margin-top: 20px;
    }
    &-image {
      border: 2px dashed $secondary;
      background-color: $white;
      padding: 10px;
      box-sizing: border-box;
      &.image-error {
        border: 2px dashed $red;
      }
      & .upload-icon {
        font-size: 50px;
        color: $hovered-red;
      }
      &-preview {
        & img {
          max-width: 400px;
          max-height: 200px;
        }
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}

.categories-modal-container {
  position: absolute;
  inset: 40px;
  box-shadow: 1px 0px 10px 0px $main;
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  height: fit-content;
}
