@import '../../../../../assets/sass/variables';

.orders-modal {
  width: 100%;

  &-close {
    text-align: end;

    & svg {
      cursor: pointer;
    }
  }

  &-title {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    color: $main;
  }

  &__content {
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      margin-top: 30px;
    }

    &-picker {
      display: flex;
      flex-direction: column;
      gap: 20px;

      &-clock {
        box-shadow: 1px 0px 8px -5px gray;
      }
    }

    &-submit {
      margin-top: 20px;
    }
  }
}

.orders-modal-container {
  position: absolute;
  inset: 40px;
  box-shadow: 1px 0px 10px 0px $main;
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  height: fit-content;

  &.delete-confirmation {
    width: 300px;
    height: 150px;
    // center it
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}