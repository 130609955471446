@import '../../../../assets/sass/variables';

.orders-page {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;

    &__create {
        display: flex;
        justify-content: flex-end;
        gap: 20px;

        &-btn {
            width: 150px;
        }
    }

    &__order {
        display: flex;
        flex-direction: column;
        gap: 20px;

        &-title {
            font-size: 24px;
            font-weight: bold;
            color: $red;
        }

        &-items {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 10px;

            &-item {
                display: flex;
                flex-direction: row;
                gap: 10px;
                box-shadow: 1px 0px 12px -5px gray;
                color: $red;
                border: 2px solid $hovered-red;
                border-radius: 10px;
                position: relative;

                &-actions {
                    position: absolute;
                    right: 0;
                    top: 0;
                    display: flex;
                    gap: 5px;
                    background-color: $hovered-red;
                    border-radius: 0 5px 0 0;
                    padding: 5px;

                    &-action {
                        color: $white;
                        cursor: pointer;

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }

                &-details {
                    border-right: 2px solid $hovered-red;

                    &-row {
                        display: flex;
                        align-items: center;
                        padding: 5px 10px;
                        box-sizing: border-box;
                        gap: 5px;

                        &-bold {
                            font-weight: bold;
                            white-space: nowrap;
                        }
                    }
                }

                &-cart {
                    white-space: nowrap;
                    padding: 5px 10px;

                    &-title {
                        font-weight: bold;
                        color: $red;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}