@import '../../../../../assets/sass/variables';

.banner-upload {
  display: flex;
  flex-direction: column;
  gap: 10px;
  &__dropzone {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px dashed $secondary;
    background-color: $white;
    min-height: 100px;
  }
  &__previews {
    display: flex;
    flex-direction: row;
    gap: 10px;
    max-height: 100px;
    &-item {
      &-image {
        position: relative;
        border: 2px dashed $main;
        height: 100px;
        img {
          height: 100%;
        }
        &-close {
          position: absolute;
          top: 2px;
          right: 5px;
          cursor: pointer;
          color: white;
          mix-blend-mode: difference;

          &:hover {
            opacity: 50%;
          }
        }
      }
    }
  }
}
