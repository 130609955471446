@import '../../../assets/sass/variables';

.cart-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &__info {
    display: flex;
    align-items: center;
    gap: 5px;
    &-image {
      & img {
        width: 45px;
        height: 45px;
        object-fit: cover;
        border-radius: 5px;
      }
    }
    &-text {
      display: flex;
      flex-direction: column;
      gap: 5px;
      &-title {
        color: $red;
        font-weight: bold;
        white-space: nowrap;
        max-width: 130px;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &__price {
    font-weight: bold;
  }
}
