@import '../../../../assets/sass/variables';

.categories-page {
  padding: 20px;
  box-sizing: border-box;
  &__add_product {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    &-btn {
      min-width: 150px;
    }
  }

  &__categories {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      color: $red;
      box-sizing: border-box;
      box-shadow: 1px 0px 12px -5px gray;
      background-color: $white;
      &-actions {
        display: flex;
        gap: 5px;
      }
    }
  }
}
