@import '../../assets/sass/variables';

.checkout-page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 40px;

  &__container {
    width: 800px;
    display: flex;
    flex-direction: column;

    &-title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      padding: 10px 0;
      margin-bottom: 20px;
      box-sizing: border-box;
      border-bottom: 3px solid $red;
    }

    &-contact {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 10px;
      margin-bottom: 20px;

      &-input {
        width: 100%;
      }
    }

    &-location {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      margin-bottom: 20px;

      & .map-error-text {
        color: $red;
      }

      &-map {
        position: relative;
        z-index: 0;

        &.map-error {
          border: 2px solid $red;
        }

        &-current {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 999;
        }

        &-text {
          position: absolute;
          top: 10px;
          left: 50%;
          transform: translateX(-50%);
          color: $red;
          z-index: 999;
          font-weight: bold;
          white-space: nowrap;

          @media screen and (max-width: 768px) {
            margin-top: 35px;
          }
        }

        & a:visited {
          color: $red;
        }

        & a:any-link {
          color: $red;
        }

        & a:hover {
          color: $red;
        }

        & a:focus {
          color: $red;
        }

        & a:active {
          color: $red;
        }
      }


      &-input {
        width: 100%;

        &-map {
          font-size: 12px;
          color: $red;
          cursor: pointer;
          text-decoration: underline;
          margin-left: 10px;

          & a:visited {
            color: $red;
          }

          & a:any-link {
            color: $red;
          }

          & a:hover {
            color: $red;
          }

          & a:focus {
            color: $red;
          }

          & a:active {
            color: $red;
          }
        }
      }
    }

    &-delivery_method {
      &-order {
        &-tab {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }

      &-tab {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }

    &-payment_method {
      display: flex;
      width: 100%;
      gap: 10px;

      &-item {
        width: 100%;
        background: $white;
        border-radius: 5px;
        padding: 5px 10px;
        text-align: center;
        box-shadow: 1px 0px 10px -5px gray;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          background-color: $hovered-red;
          color: $white;
        }

        &.active {
          cursor: default;
          background-color: $red;
          color: $white;
        }
      }
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px 0;
      margin-top: 20px;
      box-sizing: border-box;
      border-top: 3px solid $red;
      width: 100%;

      @media screen and (max-width: 768px) {
        justify-content: center;
      }

      &-total {
        display: flex;
        flex-direction: column;
        min-width: 280px;
        gap: 5px;

        @media screen and (max-width: 768px) {
          width: 100%;
          min-width: auto;
        }

        &-item {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &-value {
            font-weight: bold;
          }

          &.total {
            font-weight: bold;
            color: $red;
          }
        }
      }
    }
  }

  &__digital-clock {
    box-shadow: 1px 0px 8px -5px gray;
  }
}