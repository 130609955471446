@import '../../../../assets/sass/variables';

.statistics-page {
    padding: 20px;
    box-sizing: border-box;

    &__container {
        &-cards {
            display: flex;
            flex-direction: column;
            gap: 50px;
            width: 100%;

            @media screen and (max-width: 1168px) {
                grid-template-columns: 1fr 1fr;
            }

            @media screen and (max-width: 768px) {
                grid-template-columns: 1fr;
            }

            &-separator {
                border-bottom: 3px solid $hovered-red;
            }
        }
    }
}

.no-orders {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 28px;
    font-weight: bold;
    color: $red;
}