@import '../../../../assets/sass/variables';

.products-page {
  padding: 20px;
  box-sizing: border-box;
  &__add_product {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    &-btn {
      min-width: 150px;
    }
  }
  &__menu-items {
    &-container {
      &-title {
        text-align: center;
        color: $hovered-red;
        font-size: 20px;
        font-weight: bold;
        padding: 10px 0;
        margin-bottom: 20px;
        border-bottom: 3px solid $hovered-red;
      }
      &-products {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
      }
    }
  }
}
