@import '../../assets/sass/variables';

.delivery-conditions-page {
  display: flex;
  flex-direction: column;
  gap: 20px;
  &__schedule {
    color: $red;
    font-size: 24px;
    font-weight: bold;
  }
  &__subtitle {
    font-size: 20px;
    font-weight: bold;
  }
  &__locations {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-location {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $white;
      box-shadow: 1px 0px 12px -5px gray;
      padding: 20px;
      box-sizing: border-box;
      gap: 10px;
      &-label {
        font-weight: bold;
      }
      &-value {
        font-weight: bold;
      }
    }
  }
}
