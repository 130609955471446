@import '../../assets/sass/variables';

.cart-menu-item {
  background-color: $white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  box-shadow: 1px 0px 12px -5px gray;
  transition: all 0.3s ease 0s;
  height: 100%;
  max-height: 225px;
  &:hover {
    transform: scale(1.05);
    @media screen and (max-width: 768px) {
      transform: none;
    }
  }
  &__image {
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 72%;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
    cursor: pointer;
    & img {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      -o-object-fit: cover;
      object-fit: cover;
    }
    &-props {
      display: flex;
      gap: 5px;
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 1;
      &-prop {
        background-color: $white;
        border-radius: 5px;
        padding: 3px;
        box-sizing: border-box;
        color: rgb(34, 200, 34);
        font-weight: bold;
      }
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &-details {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 0 10px;
      box-sizing: border-box;
      &-name {
        flex: 1 1 auto;
        font-weight: 500;
        font-size: 12px;
        font-weight: 600;
        line-height: 13px;
        color: $main;
        display: block;
        cursor: pointer;
        &:hover {
          color: $hovered-red;
        }
      }
      &-price_weight {
        display: flex;
        align-items: center;
        gap: 10px;
        &-price {
          color: $red;
          font-weight: bold;
          display: flex;
          align-items: center;
          font-size: 12px;
          &-discount {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 2px;
            &-old {
              text-decoration: line-through;
            }
            &-new {
              font-size: 14px;
            }
          }
        }
        &-weight {
          font-size: 12px;
          color: $red;
        }
      }
    }
    &-add_to_cart {
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}
