@import './assets/sass/_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

:root {
  --swiper-theme-color: #fff;
}
html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  padding: 0 !important;
  overflow: auto !important;
}
.app-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.full-height {
  height: 100vh;
}
.body-no-scroll {
  overflow: hidden !important;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $red;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $hovered-red;
}
