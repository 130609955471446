@import '../../../../../assets/sass/variables';


.stats-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    &__title {
        font-size: 24px;
        font-weight: bold;
        color: $red;
        margin-bottom: 20px;
    }

    &__content {
        width: 100%;

        &-months {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(20px, 100px));
            gap: 5px;
            width: 100%;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 2px solid $hovered-red;

            &-month {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px;
                border-radius: 5px;
                cursor: pointer;
                transition: all 0.3s ease;

                &:hover {
                    background-color: $hovered-red;
                    color: #fff;
                }

                &.active {
                    background-color: $red;
                    color: #fff;
                }
            }
        }

        &-title {
            font-size: 24px;
            font-weight: bold;
            color: $red;
            text-align: center;
            margin-bottom: 20px;
        }

        &-stats {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            text-align: center;
            gap: 10px;
            width: 100%;
            margin-bottom: 20px;

            &-stat {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 5px;
                width: 100%;

                &-label {
                    font-size: 16px;
                    font-weight: bold;
                    color: $main;
                }

                &-value {
                    font-size: 24px;
                    font-weight: bold;
                    color: $red;
                }
            }
        }
    }

    .table-header-text {
        font-size: 18px;
        font-weight: bold;
        color: $red;
    }

    .table-body-row {
        font-size: 16px;
        font-weight: bold;
        color: $main;

        &:hover {
            background-color: rgba($red, 0.1);
            cursor: pointer;
        }
    }
}