@import '../../../../assets/sass/variables';

.admin-menu-item {
  background-color: $white;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  box-shadow: 1px 0px 12px -5px gray;
  transition: all 0.3s ease 0s;
  &__image {
    display: block;
    max-width: 200px;
    height: 200px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    &-props {
      position: absolute;
      display: flex;
      align-items: center;
      gap: 5px;
      top: 5px;
      right: 5px;
      z-index: 1;
      &-prop {
        background-color: $white;
        border-radius: 5px;
        padding: 3px;
        box-sizing: border-box;
        &.discount {
          color: $red;
        }
      }
    }
    & img {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    gap: 30px;
    &-details {
      padding: 0 10px;
      box-sizing: border-box;
      &-name {
        flex: 1 1 auto;
        font-weight: 500;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        color: $main;
        display: block;
        margin-bottom: 8px;
      }
      &-price {
        color: $red;
        font-weight: bold;
      }
    }
    &-add_to_cart {
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      gap: 10px;
      height: 100%;
    }
  }
}
