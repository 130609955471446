@import '../../assets/sass/variables';

.footer {
  margin-top: 30px;
  // background-color: rgba($color: $main, $alpha: 0.3);
  background-color: $black;
  box-shadow: 0 0 12px rgba($color: #000000, $alpha: 1);

  &__content {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 20px;
    max-width: 1200px;
    height: 100%;
    box-sizing: border-box;
    &-contact {
      display: flex;
      flex-direction: column;
      gap: 20px;
      &-item {
        display: flex;
        align-items: center;
        gap: 20px;
        &-icon {
          color: $red;
          & svg {
            width: 20px;
            height: 20px;
          }
        }
        &-info {
          display: flex;
          flex-direction: column;
          gap: 5px;
          color: $white;
          &-label {
            font-weight: bold;
          }
          &-value {
            &:hover {
              color: $red;
            }
            & svg {
              width: 20px;
              height: 20px;
              cursor: pointer;
              path {
                fill: $white;
              }
              &:hover {
                path {
                  fill: $red;
                }
              }
            }
          }
        }
      }
    }

    &-list {
      &-divider {
        border-bottom: 1px solid $red;
        width: 40px;
        margin: 8px 0 8px 4px;
      }
      &-title {
        font-weight: bold;
        font-size: 18px;
        color: $white;
      }
      &-item {
        cursor: pointer;
        color: $white;
        &:hover {
          color: $red;
        }
      }
    }
  }
}
